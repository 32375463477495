/**
 * https://v4.mui.com/components/tables/#basic-table
 */
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

import { ExampleDocument } from 'types/documentTypes'
import { IconButton } from '@material-ui/core'

import { DateTime } from 'luxon'

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
})

type ExamplesTableProps = {
  rows: ExampleDocument[]
  onDelete: (id: string) => void
}

export default function ExamplesTable(props: ExamplesTableProps) {
  const classes = useStyles(props)

  const { rows, onDelete } = props

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Industry</TableCell>
            <TableCell align="right">Created</TableCell>
            <TableCell align="right">Updated</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index} onClick={() => console.info(JSON.stringify(row, null, 2))}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.industry}</TableCell>
              <TableCell align="right">
                {row.created?.seconds &&
                  DateTime.fromSeconds(row.created?.seconds).toLocaleString(
                    DateTime.DATETIME_FULL_WITH_SECONDS
                  )}
              </TableCell>
              <TableCell align="right">
                {row.updated?.seconds &&
                  DateTime.fromSeconds(row.updated?.seconds).toLocaleString(
                    DateTime.DATETIME_FULL_WITH_SECONDS
                  )}
              </TableCell>
              <TableCell align="right">
                <IconButton onClick={() => onDelete(row.id)} size="small">
                  <RemoveCircleIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
