import { Context, Dispatch, SetStateAction, createContext } from 'react'
import { GlobalStateInterface } from 'types/contextTypes'

// type UserContextType = {
//   Provider: object
//   appUser: object
// }

// type AppUserContextType = {

// type AppUserContextType = {
//   appUser: any
//   setAppUser: Dispatch<any>
// }

export const UserContext: Context<any> = createContext({
  appUser: {},
  setAppUser: () => {},
})

export const GlobalStateContext = createContext({
  state: {} as Partial<GlobalStateInterface>,
  setState: {} as Dispatch<SetStateAction<Partial<GlobalStateInterface>>>,
})

// export const LoginContext = createContext(value => {
//   return value
// })

// TODO: This "any" fixes the TS problems, but causes problems in Jest
// TODO: Need to fix Jest to allow TypeScript
// export const AgencyContext: any = createContext(() => {})

// Attempts to fix the TS errors, but don't work
// interface AgencyContextType {
//   Provider: object
//   appAgency: object
//   setAppAgency: Dispatch<any>
// }

// export const AgencyContext: AgencyContextType = createContext({
//   appAgency: {
//     agencyId: Number,
//   },
//   setAppAgency: () => {},
// })
