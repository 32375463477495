import { dbh } from 'config/firebaseConfig'
import { collection, query, getDocs, where } from 'firebase/firestore'

import { CACHE_COLLECTION, USER_COLLECTION } from 'utils/constants'
import { Service } from './Service'
import { CacheDocument } from 'types/documentTypes'

export class UserService extends Service {
  constructor() {
    super(USER_COLLECTION)
  }
}
