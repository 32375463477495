import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import * as Realm from 'realm-web'

import { makeStyles } from '@material-ui/core'
import { APP_ID } from 'utils/constants'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
    },
  }),
  { name: 'ConfirmEmail' }
)

export const ConfirmEmail = (props: {}) => {
  const classes = useStyles(props)

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()
  const [token, setToken] = useState<string>('')
  const [tokenId, setTokenId] = useState<string>('')

  const [message, setMessage] = useState<string>('')

  useEffect(() => {
    console.log('ConfirmEmail')
    const token = searchParams.get('token') || ''
    const tokenId = searchParams.get('tokenId') || ''
    console.log({ token, tokenId })
    setToken(token)
    setTokenId(tokenId)
  }, [])

  useEffect(() => {
    const confirmEmail = async () => {
      if (token && tokenId) {
        try {
          const app = new Realm.App({ id: APP_ID })
          await app.emailPasswordAuth.confirmUser({ token, tokenId })
          setMessage('email confirmed')
        } catch (err) {
          console.error('CONFIRM EMAIL ERROR: ', err)
          setMessage('email confirmation expired')
        }
      }
    }
    confirmEmail()
  }, [token, tokenId])

  return (
    <div className={classes.root}>
      CONFIRM EMAIL
      <div>{message}</div>
    </div>
  )
}
