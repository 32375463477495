// This service completely hides the data store from the rest of the app.
// No other part of the app knows how the data is stored. If anyone wants
// to read or write data, they have to go through this service.

import { dbh } from 'config/firebaseConfig'
import {
  collection,
  query,
  getDocs,
  addDoc,
  orderBy,
  limit,
  deleteDoc,
  doc,
  getDoc,
  getCountFromServer,
  where,
} from 'firebase/firestore'
import { TEST_COLLECTION } from 'utils/constants'

type Test = {
  name: string
  description?: string
}

export const createTest = async (props: Test) => {
  const data = { ...props }
  const docRef = await addDoc(collection(dbh, TEST_COLLECTION), data)
  return { id: docRef.id, ...data }
}

export const countTests = async () => {
  const coll = collection(dbh, TEST_COLLECTION)
  const snapshot = await getCountFromServer(coll)
  return snapshot.data().count
}

export const fetchTest = async (id: string) => {
  const docRef = doc(dbh, TEST_COLLECTION, id)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return { id: docSnap.id, ...docSnap.data() }
  } else {
    console.warn('No such document!')
  }
}

export const fetchAllTests = async () => {
  const snapshot = await getDocs(collection(dbh, TEST_COLLECTION))
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }))
}

export const fetchTests = async () => {
  const snapshot = await getDocs(
    query(collection(dbh, TEST_COLLECTION), orderBy('date', 'desc'), limit(20))
  )
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }))
}

export const searchTests = async (key: string, operator: string, value: string) => {
  const q = query(collection(dbh, TEST_COLLECTION), where(key, '==', value))

  const snapshot = await getDocs(q)
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }))
}

export async function deleteTest(id: string) {
  await deleteDoc(doc(dbh, TEST_COLLECTION, id))
}
