import { ChangeEvent, useEffect, useState } from 'react'

import { Button, FormControl, TextField, makeStyles } from '@material-ui/core'

import { useFirebaseAuth } from 'services/hooks/useFirebaseAuth'
import { useUserExamples } from 'services/hooks/useUserExamples'

import { SignIn, SignOut } from 'services/AuthService'
import { ExampleService } from 'services/ExampleService'

import { Draft, ExampleDocument } from 'types/documentTypes'

import ExamplesTable from './ExampleTable'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      margin: theme.spacing(3),
    },

    field: {
      marginBottom: theme.spacing(2),
    },

    user: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'App' }
)

const exampleService = new ExampleService()

const HookCrud = (props: {}) => {
  const classes = useStyles(props)

  const [values, setValues] = useState<Draft>({})
  const [query, setQuery] = useState('')
  const [results, setResults] = useState<ExampleDocument[]>([])

  const { user, loading: userLoading } = useFirebaseAuth()
  const { examples, loading: examplesLoading } = useUserExamples()

  useEffect(() => {
    // Filter results client-side since Firestore doesn't support full-text search
    const filtered = examples.filter(item => {
      return (
        item.name?.toLowerCase().includes(query.toLowerCase()) ||
        item.industry?.toLowerCase().includes(query.toLowerCase())
      )
    })
    setResults(filtered)
  }, [query, examples])

  const handleFieldChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleQueryChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setQuery(e.target.value)
  }

  const handleSubmit = async () => {
    if (user) {
      const example = await exampleService.createUserDocument(user.uid, values)
    }
  }

  const handleDelete = async (exampleId: string) => {
    if (user) {
      await exampleService.deleteUserDocument(user.uid, exampleId)
    }
  }

  return (
    <div>
      <header>
        <h1>HookCrud</h1>
        {userLoading ? (
          <p>Loading...</p>
        ) : (
          <div className={classes.user}>{!user ? <SignIn /> : <SignOut />}</div>
        )}
      </header>
      <div>
        <FormControl>
          <div className={classes.field}>
            <TextField
              label="Name"
              name="name"
              variant="outlined"
              size="small"
              onChange={e => handleFieldChange(e)}
            />
          </div>
          <div className={classes.field}>
            <TextField
              label="Industry"
              name="industry"
              variant="outlined"
              size="small"
              onChange={e => handleFieldChange(e)}
            />
          </div>
          <div className={classes.field}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </FormControl>
      </div>
      <div>
        <FormControl>
          <div className={classes.field}>
            <TextField
              label="Search"
              name="query"
              variant="outlined"
              size="small"
              onChange={e => handleQueryChange(e)}
            />
          </div>
        </FormControl>
      </div>
      <div>
        {examplesLoading ? (
          <p>Loading...</p>
        ) : (
          <ExamplesTable rows={results} onDelete={handleDelete} />
        )}
      </div>
    </div>
  )
}

export default HookCrud
