import { useState, useEffect } from 'react'
import { collection, documentId, onSnapshot, query, where } from 'firebase/firestore'

import { dbh } from 'config/firebaseConfig'
import { ExampleDocument } from 'types/documentTypes'
import { EXAMPLE_COLLECTION, USER_EXAMPLE_COLLECTION } from 'utils/constants'

import { useFirebaseAuth } from './useFirebaseAuth'

export const useUserExamples = () => {
  const [loading, setLoading] = useState(true)
  const [exampleIds, setExampleIds] = useState<string[]>([])
  const [examples, setExamples] = useState<ExampleDocument[]>([])

  const { user, loading: userLoading } = useFirebaseAuth()

  useEffect(() => {
    if (user) {
      const coll = collection(dbh, USER_EXAMPLE_COLLECTION)
      const q = query(coll, where('userId', '==', user.uid))

      // ITS Gujrat
      // https://youtu.be/JzshL7Ddf5E?t=255
      const unsubscribe = onSnapshot(q, results => {
        const exampleIds = results.docs.map(doc => doc.data().exampleId)
        setExampleIds(exampleIds)
      })

      return () => unsubscribe()
    }
  }, [user])

  useEffect(() => {
    if (exampleIds.length > 0) {
      const examplesColl = collection(dbh, EXAMPLE_COLLECTION)

      // Use "in" operator to get all the examples in one query
      // https://firebase.google.com/docs/firestore/query-data/queries#in_not-in_and_array-contains-any
      const q = query(examplesColl, where(documentId(), 'in', exampleIds)) // https://stackoverflow.com/a/48423626

      // ITS Gujrat
      // https://youtu.be/JzshL7Ddf5E?t=255
      const unsubscribe = onSnapshot(q, results => {
        setExamples(
          // https://firebase.google.com/docs/firestore/query-data/listen#listen_to_multiple_documents_in_a_collection
          results.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }))
        )
        setLoading(false)
      })

      return () => unsubscribe()
    }
  }, [exampleIds])

  return { loading, examples }
}
