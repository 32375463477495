import { useState } from 'react'
import { Outlet } from 'react-router-dom'

import { GoogleOAuthProvider } from '@react-oauth/google'

import { makeStyles } from '@material-ui/core'

import { GlobalStateProvider } from 'contexts/GlobalStateProvider'
import { AppUserProvider } from 'contexts/AppUserProvider'

import { UserContext } from 'utils/contexts'
import { GOOGLE_CLIENT_ID } from 'utils/constants'

// createStyles (old) vs makeStyles (new)
// https://smartdevpreneur.com/material-ui-makestyles-usestyles-createstyles-and-withstyles-explained/
const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      // justifyContent: 'center',
      // alignItems: 'center',
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  }),
  { name: 'AppContainer' }
)

const AppContainer = (props: any) => {
  const classes = useStyles(props)

  const [appUser, setAppUser] = useState<any>({})

  return (
    <div className={classes.root}>
      <GlobalStateProvider>
        <AppUserProvider>
          <UserContext.Provider value={{ appUser, setAppUser }}>
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <Outlet />
            </GoogleOAuthProvider>
          </UserContext.Provider>
        </AppUserProvider>
      </GlobalStateProvider>
    </div>
  )
}

export default AppContainer
