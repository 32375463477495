import { SeriesMarker, Time } from 'lightweight-charts'
import { useMemo } from 'react'
import { Chart, ChartOnCrosshairMoveSubscriber, LineSeries } from 'react-lightweight-charts-simple'

const samples = [
  { value: 1, time: '2022-01-01' },
  { value: 8, time: '2022-01-02' },
  { value: 10, time: '2022-01-03' },
  { value: 20, time: '2022-01-04' },
  { value: 3, time: '2022-01-05' },
  { value: 43, time: '2022-01-06' },
  { value: 41, time: '2022-01-07' },
  { value: 43, time: '2022-01-08' },
  { value: 56, time: '2022-01-09' },
  { value: 46, time: '2022-01-10' },
]

const markers: SeriesMarker<Time>[] = [
  {
    time: { year: 2018, month: 12, day: 23 },
    position: 'inBar',
    color: 'green',
    shape: 'circle',
    text: 'Buy',
  },
  {
    time: { year: 2022, month: 1, day: 6 },
    position: 'inBar',
    color: 'red',
    shape: 'circle',
    text: 'Sell',
  },
]

export const LineChart = () => {
  // Memoize data to prevent setting data multiple times
  const data = useMemo(() => [...samples], [])

  // Line Chart
  // https://tradingview.github.io/lightweight-charts/docs/series-types#line
  return (
    <Chart height={300}>
      <ChartOnCrosshairMoveSubscriber handler={e => console.log('HI')} />
      <LineSeries
        data={data}
        options={{
          color: '#26a69a',
        }}
        markers={markers}
      >
        {/* <Tooltip content={TooltipContent} /> */}
      </LineSeries>
    </Chart>
  )
}
