// This service completely hides the data store from the rest of the app.
// No other part of the app knows how the data is stored. If anyone wants
// to read or write data, they have to go through this service.

import { dbh } from 'config/firebaseConfig'
import {
  collection,
  query,
  getDocs,
  addDoc,
  orderBy,
  limit,
  Timestamp,
  deleteDoc,
  doc,
} from 'firebase/firestore'
import { ExampleDocument } from 'types/documentTypes'
import { EXAMPLE_COLLECTION } from 'utils/constants'
import { Service } from './Service'

export const getCollection = () => collection(dbh, EXAMPLE_COLLECTION)

export class ExampleService extends Service {
  constructor() {
    super(EXAMPLE_COLLECTION)
  }

  async fetchExamples() {
    const snapshot = await getDocs(
      query(collection(dbh, EXAMPLE_COLLECTION), orderBy('date', 'desc'), limit(20))
    )
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }))
  }

  async deleteExample(id: string) {
    // https://firebase.google.com/docs/firestore/manage-data/delete-data
    await deleteDoc(doc(dbh, EXAMPLE_COLLECTION, id))
  }
}
