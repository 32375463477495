import { useMemo } from 'react'
import { Chart, BaselineSeries } from 'react-lightweight-charts-simple'

export const BaselineSeriesChart = () => {
  // Memoize data to prevent setting data multiple times
  const data = useMemo(
    () => [
      { value: 1, time: '2022-01-01' },
      { value: 8, time: '2022-01-02' },
      { value: 10, time: '2022-01-03' },
      { value: 20, time: '2022-01-04' },
      { value: 3, time: '2022-01-05' },
      { value: 43, time: '2022-01-06' },
      { value: 41, time: '2022-01-07' },
      { value: 43, time: '2022-01-08' },
      { value: 56, time: '2022-01-09' },
      { value: 46, time: '2022-01-10' },
    ],
    []
  )

  // Baseline Chart
  // https://tradingview.github.io/lightweight-charts/docs/series-types#baseline
  return (
    <Chart height={300}>
      <BaselineSeries
        data={data}
        options={{
          baseValue: { type: 'price', price: 25 },
          topLineColor: 'rgba( 38, 166, 154, 1)',
          topFillColor1: 'rgba( 38, 166, 154, 0.28)',
          topFillColor2: 'rgba( 38, 166, 154, 0.05)',
          bottomLineColor: 'rgba( 239, 83, 80, 1)',
          bottomFillColor1: 'rgba( 239, 83, 80, 0.05)',
          bottomFillColor2: 'rgba( 239, 83, 80, 0.28)',
        }}
      />
    </Chart>
  )
}
