import { useEffect, useState } from 'react'

import { Button, makeStyles } from '@material-ui/core'

import { useMongoAnonUser } from 'services/hooks/useMongoAnonUser'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      // justifyContent: 'center',
      // alignItems: 'center',
      // margin: theme.spacing(3),
    },

    buttons: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'AdminPage' }
)

export const AdminPage = (props: {}) => {
  const classes = useStyles(props)

  const [userIds, setUserIds] = useState<string[]>([])

  // const { app, user, loading: userLoading } = useMongoAnonUser()

  // useEffect(() => {
  //   console.log('CURRENT USER: ' + app.currentUser?.id)

  //   // Get an object with all Users, where the keys are the User IDs
  //   for (const userId in app.allUsers) {
  //     const user = app.allUsers[userId]
  //     console.log(`User with id ${user.id} is ${user.isLoggedIn ? 'logged in' : 'logged out'}`)
  //   }

  //   const list = Object.keys(app.allUsers)
  //   console.log(list)
  //   setUserIds(list)
  // }, [app])

  const deleteAllUsers = async () => {
    console.log('deleteAllUsers')
    // console.log('CURRENT USER: ' + app.currentUser?.id)

    // const mongo = app.currentUser?.mongoClient('Cluster0')
    // const dbh = mongo?.db('intrinsic')

    // await app.deleteUser(user.id)
    const lastId = '644ce58b6e2fddb0da75aa9b'

    // const lastId = userIds[userIds.length - 1]
    // const lastId = userIds[theId]
    // userIds.pop()
    // const lastId = userIds.pop()

    // console.log('LAST ID: ', lastId)

    // const userToDelete = app.allUsers[lastId]

    // const deleteResult = await app.deleteUser(userToDelete)
    // console.log('DELETE RESULT: ', deleteResult)

    // setUserIds([...userIds])
  }

  return (
    <div className={classes.root}>
      <div className={classes.buttons}>
        <Button variant="contained" color="primary" onClick={deleteAllUsers}>
          Delete All Users
        </Button>
      </div>
    </div>
  )
}
