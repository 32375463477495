import { Button, makeStyles } from '@material-ui/core'
import DataGridDemo from 'examples/mui/DataGridDemo'
import { useFirebaseAuth } from 'services/hooks/useFirebaseAuth'

import { DateTime } from 'luxon'

import preval from 'preval.macro'

import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      // justifyContent: 'center',
      // alignItems: 'center',
      // margin: theme.spacing(3),
    },

    field: {
      marginBottom: theme.spacing(2),
    },

    user: {
      marginBottom: theme.spacing(2),
    },

    buttons: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'Dashboard' }
)

const timeZone = 'America/Los_Angeles'
const buildTimestamp = preval`module.exports = new Date().getTime();`

export const DashboardDemo = (props: {}) => {
  const classes = useStyles(props)

  const { user, loading: userLoading } = useFirebaseAuth()

  return (
    <div className={classes.root}>
      <div className={classes.buttons}>
        <Button variant="contained" color="primary">
          <AddIcon /> Stock
        </Button>
      </div>
      <div>
        <DataGridDemo />
      </div>
      <div>Build Date: {preval`module.exports = new Date().toLocaleString();`}.</div>
      <div>
        Luxon local time:{' '}
        <div>
          {DateTime.fromMillis(buildTimestamp).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}
        </div>
        <div>
          {DateTime.fromMillis(buildTimestamp, { zone: timeZone }).toLocaleString(
            DateTime.DATETIME_FULL_WITH_SECONDS
          )}
        </div>
      </div>
    </div>
  )
}
