import { useEffect } from 'react'

import * as Realm from 'realm-web'

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
    },
  }),
  { name: 'Auth' }
)

export const Auth = (props: {}) => {
  const classes = useStyles(props)

  useEffect(() => {
    console.log('Auth')
    Realm.handleAuthRedirect()
  }, [])

  return <div className={classes.root}>REALM AUTH REDIRECT</div>
}
