/**
 * LocalStorage: does not expire
 * SessionStorage: expires when the browser is closed
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage
 */
type BrowserStorage = {
  [key: string]: string
}

export const setLocalStorage = (key: string, value: any) => {
  console.log('SET LOCAL STORAGE: ', key, value)
  localStorage.setItem(key, JSON.stringify(value))
}

export const getLocalStorage = (key: string) => {
  const item = localStorage.getItem(key) || ''
  console.log('GET LOCAL STORAGE: ', key, item)
  return item !== '' ? JSON.parse(item) : ''
}

export const dumpLocalStorage = () => {
  const storage: BrowserStorage = {}
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i) || ''
    const value = localStorage.getItem(localStorage.key(i) || '') || ''
    storage[key] = value
  }
  return { ...storage }
}

export const dumpSessionStorage = () => {
  const storage: BrowserStorage = {}
  for (let i = 0; i < window.sessionStorage.length; i++) {
    const key = sessionStorage.key(i) || ''
    const value = sessionStorage.getItem(sessionStorage.key(i) || '') || ''
    storage[key] = value
  }
  return { ...storage }
}

export const consoleSessionStorage = (key: string) => {
  let storage
  if (key) {
    console.log('DUMP ITEM: ', key)
    const item = window.sessionStorage.getItem(key) || ''
    storage = JSON.parse(item)
  } else {
    console.log('DUMP ALL: ', key)
    storage = dumpSessionStorage()
  }
  console.info('SESSION: ', JSON.stringify(storage, null, 2))
}
