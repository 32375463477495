/**
 * Initial CRUD examples, but they are not tied to a user (all data is global to all users)
 */
import { ChangeEvent, useEffect, useState } from 'react'
import { collection, onSnapshot } from 'firebase/firestore'

import { Button, FormControl, TextField, makeStyles } from '@material-ui/core'

import { dbh } from 'config/firebaseConfig'

import { SignIn, SignOut } from 'services/AuthService'
import { useFirebaseAuth } from 'services/hooks/useFirebaseAuth'

import { Draft, ExampleDocument } from 'types/documentTypes'

import { EXAMPLE_COLLECTION } from 'utils/constants'

import ExamplesTable from './ExampleTable'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      // justifyContent: 'center',
      // alignItems: 'center',
      margin: theme.spacing(3),
    },

    field: {
      marginBottom: theme.spacing(2),
    },

    user: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'App' }
)

const CRUD = (props: {}) => {
  const classes = useStyles(props)

  const { user, loading: userLoading } = useFirebaseAuth()

  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const [values, setValues] = useState<Draft>({})

  const [examples, setExamples] = useState<ExampleDocument[]>([])

  useEffect(() => {
    const ref = collection(dbh, EXAMPLE_COLLECTION)

    // ITS Gujrat
    // https://youtu.be/JzshL7Ddf5E?t=255
    const unsubscribe = onSnapshot(ref, results => {
      setExamples(
        // https://firebase.google.com/docs/firestore/query-data/listen#listen_to_multiple_documents_in_a_collection
        results.docs.map(doc => {
          console.log('doc.data(): ', doc.data())
          return {
            id: doc.id,
            ...doc.data(),
          }
        })
      )
    })

    return () => unsubscribe()
  }, [user])

  const handleFieldChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    console.log(e.target.name)
    console.log(e.target.value)

    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleSubmit = async () => {
    console.log('SUBMIT')
  }

  const handleDelete = (id: string) => {
    console.log('DELETE', id)
    // deleteExample(id)
  }

  console.log('VALUES: ', values)

  console.log('EXAMPLES: ', examples)

  return (
    <div>
      <header>
        <h1>CRUD</h1>
        <div className={classes.user}>{!user ? <SignIn /> : <SignOut />}</div>
      </header>
      <FormControl>
        <div className={classes.field}>
          <TextField
            label="Name"
            name="name"
            variant="outlined"
            size="small"
            onChange={e => handleFieldChange(e)}
          />
        </div>
        <div className={classes.field}>
          <TextField
            label="Industry"
            name="industry"
            variant="outlined"
            size="small"
            onChange={e => handleFieldChange(e)}
          />
        </div>
        <div className={classes.field}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
        <div>
          <ExamplesTable rows={examples} onDelete={handleDelete} />
        </div>
      </FormControl>
    </div>
  )
}

export default CRUD
