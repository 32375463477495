import { createContext, useState, useContext, Dispatch, SetStateAction, ReactNode } from 'react'

export interface GlobalStateInterface {
  firstname: string
  lastname: string
  age: string
}

const GlobalStateContext = createContext({
  state: {} as Partial<GlobalStateInterface>,
  setState: {} as Dispatch<SetStateAction<Partial<GlobalStateInterface>>>,
})

export const GlobalStateProvider = ({
  children,
  value = {} as GlobalStateInterface,
}: {
  children: ReactNode
  value?: Partial<GlobalStateInterface>
}) => {
  const [state, setState] = useState(value)
  return (
    <GlobalStateContext.Provider value={{ state, setState }}>
      {children}
    </GlobalStateContext.Provider>
  )
}

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext)
  if (!context) {
    throw new Error('useGlobalState must be used within a GlobalStateContext')
  }
  return context
}
