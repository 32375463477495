import { makeStyles } from '@material-ui/core'

// import { useMongoAnonUser } from 'services/hooks/useMongoAnonUser'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      // justifyContent: 'center',
      // alignItems: 'center',
      // margin: theme.spacing(3),
    },

    field: {
      marginBottom: theme.spacing(2),
    },

    user: {
      marginBottom: theme.spacing(2),
    },

    buttons: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'Dashboard' }
)

export const Dashboard = (props: {}) => {
  const classes = useStyles(props)

  // const { app, user, loading: userLoading } = useMongoAnonUser()

  // useEffect(() => {
  //   const retrieveData = async () => {
  //     if (userLoading) return
  //     console.log('USER: ', user)

  //     const mongo = app.currentUser?.mongoClient('Cluster0')
  //     const coll = mongo?.db('intrinsic').collection('stock_data')

  //     const findResult = await coll?.find({ symbol: 'AAPL' })
  //     console.log('findResult: ', findResult)

  //     await findResult?.forEach(console.dir)
  //   }
  //   retrieveData()
  // }, [app, user, userLoading])

  return (
    <div className={classes.root}>{/* <StockBacktestChart market="nasdaq" symbol="AAPL" /> */}</div>
  )
}
