import { useMemo } from 'react'
import { Chart, AreaSeries } from 'react-lightweight-charts-simple'

export const SimpleWrapper = () => {
  // Memoize data to prevent setting data multiple times
  const data = useMemo(
    () => [
      { time: '2022-02-01', value: 100 },
      { time: '2022-02-02', value: 300 },
      { time: '2022-02-03', value: 500 },
      { time: '2022-02-04', value: 700 },
      { time: '2022-02-05', value: 600 },
      { time: '2022-02-06', value: 400 },
      { time: '2022-02-07', value: 200 },
      { time: '2022-02-08', value: 500 },
      { time: '2022-02-09', value: 800 },
      { time: '2022-02-10', value: 1000 },
    ],
    []
  )

  return (
    <Chart height={300}>
      <AreaSeries data={data} />
    </Chart>
  )
}
