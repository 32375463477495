import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import path from 'path'
import parseUrl from 'parse-url'

import * as Realm from 'realm-web'

import GoogleOneTapLogin from 'react-google-one-tap-login'

import { Avatar, Button, makeStyles } from '@material-ui/core'

import { APP_ID, GOOGLE_CLIENT_ID, STORAGE_GOOGLE_USER } from 'utils/constants'

import { useAppUser } from 'contexts/AppUserProvider'
import { getLocalStorage, setLocalStorage } from 'utils/storage'
import { IGoogleEndPointResponse } from 'react-google-one-tap-login/dist/types/types'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      // justifyContent: 'center',
      // alignItems: 'center',
      marginTop: theme.spacing(3),
    },

    header: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(2),
    },

    body: {},

    user: {},

    avatar: {
      marginRight: theme.spacing(1),
    },

    userInfo: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
    },

    oneTap: {
      margin: theme.spacing(1),
    },

    signin: {
      display: 'flex',
      // flex: 1,
      alignItems: 'center',
    },

    name: {
      display: 'flex',
      flex: 1,
    },

    signOutButton: {
      marginLeft: theme.spacing(2),
    },

    buttons: {
      display: 'flex',
      flex: 1,
      marginTop: theme.spacing(10),
    },
  }),
  { name: 'DashboardContainer' }
)

const DashboardContainer = (props: {}) => {
  const classes = useStyles(props)

  const { appUser, setAppUser } = useAppUser()

  const [userLoading, setUserLoading] = useState(true)

  useEffect(() => {
    const googleUser = getLocalStorage(STORAGE_GOOGLE_USER)
    console.log('GOOGLE USER FROM STORAGE: ', googleUser)
    if (googleUser) setAppUser(googleUser)
    setUserLoading(false)
  }, [])

  const handleRegisterUser = async () => {
    const app = new Realm.App({ id: APP_ID })
    const email = 'masaok@gmail.com'
    const password = 'kitamura'
    const response = await app.emailPasswordAuth.registerUser({ email, password })
    console.log('CREATE EMAIL/PASS USER RESPONSE: ', response)
  }

  const handleLoginEmailPassword = async () => {
    const email = 'masaok@gmail.com'
    const password = 'kitamura'

    const credentials = Realm.Credentials.emailPassword(email, password)

    const app = new Realm.App({ id: APP_ID }) // TODO: put this in a context?

    // Authenticate the user
    const user = await app.logIn(credentials)

    console.log('USER: ', user)

    // `App.currentUser` updates to match the logged in user
    console.assert(user.id === app.currentUser?.id, 'hi')

    console.log('CURRENT USER: ', app.currentUser)

    const theUser = {
      id: user.id,
      email: user.profile.email,
    }

    // console.log(response)
    // setLocalStorage(STORAGE_GOOGLE_USER, response)
    // setAppUser(response)
    // setUserLoading(false)
    return user
  }

  const handleOneTapSignIn = async (response: IGoogleEndPointResponse) => {
    console.log('RESPONSE: ', response)
    const app = new Realm.App({ id: APP_ID })

    // TODO: Need to build JWT token here from response.id_token

    // const credentials = Realm.Credentials.google({ idToken: token })
    // const user = await app.logIn(credentials)
    // console.log('REALM USER LOGGED IN: ', user)

    // console.log(response)
    // setLocalStorage(STORAGE_GOOGLE_USER, response)
    // setAppUser(response)
    // setUserLoading(false)
  }

  const handleSignIn = async (response: any) => {
    console.log('RESPONSE SUB TOKEN: ', response.sub)
    const app = new Realm.App({ id: APP_ID })

    const parsed = parseUrl(window.location.href)
    const hrefBase = parsed.protocol + '://' + parsed.resource + ':' + parsed.port

    const redirectUrl = `${hrefBase}/auth`
    console.log('REDIRECT URL: ', redirectUrl)

    const credentials = Realm.Credentials.google({ redirectUrl })
    const user = await app.logIn(credentials)
    console.log('REALM USER LOGGED IN: ', user)

    // console.log(response)
    // setLocalStorage(STORAGE_GOOGLE_USER, response)
    // setAppUser(response)
    // setUserLoading(false)
  }

  const handleSignOut = () => {
    setLocalStorage(STORAGE_GOOGLE_USER, null)
    setAppUser({})
  }

  // console.log('APP USER: ', appUser)
  // console.log('PHOTO: ', user?.photoURL)

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {userLoading ? (
          <p>Loading...</p>
        ) : (
          <div className={classes.signin}>
            {/* <FirebaseSignIn /> */}

            {/* If you are not logged in, you will be prompted to log in first. */}
            {Object.keys(appUser).length === 0 ? (
              <>
                {/* Show the email/pass form */}
                <div className={classes.buttons}>
                  <Button color="primary" variant="contained" onClick={handleSignIn}>
                    Google Sign In
                  </Button>

                  <Button color="primary" variant="contained" onClick={handleRegisterUser}>
                    Register User
                  </Button>

                  <Button color="primary" variant="contained" onClick={handleLoginEmailPassword}>
                    Email / Password Login
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Avatar
                  className={classes.avatar}
                  alt={appUser.name || undefined}
                  src={appUser.picture || undefined}
                />
                <div className={classes.name}>{appUser.name}</div>
                <div className={classes.signOutButton}>
                  <Button color="primary" variant="contained" onClick={handleSignOut}>
                    Sign Out
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <div className={classes.body}>
        <Outlet />
      </div>
    </div>
  )
}

export default DashboardContainer
