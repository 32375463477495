import { Routes, Route, BrowserRouter as Router } from 'react-router-dom'

import { GoogleOAuthProvider } from '@react-oauth/google'

import AppContainer from 'containers/AppContainer'

import CRUD from 'examples/crud/CRUD'
import HookCrud from 'examples/crud/HookCrud'
import DataGridDemo from 'examples/mui/DataGridDemo'

import { Auth } from 'pages/auth/Auth'
import { ConfirmEmail } from 'pages/auth/ConfirmEmail'

import { Dashboard } from 'pages/dashboard/Dashboard'
import { DashboardDemo } from 'pages/dashboard/DashboardDemo'
import DashboardContainer from 'pages/dashboard/DashboardContainer'

import Tests from 'pages/tests/Tests'
import StockList from 'pages/list/StockList'

import { AreaSeriesChart } from 'examples/tradingview/AreaSeriesChart'
import { SimpleWrapper } from 'examples/tradingview/SimpleWrapper'
import { SimpleBarSeriesChart } from 'examples/tradingview/SimpleBarSeriesChart'
import { BaselineSeriesChart } from 'examples/tradingview/BaselineSeriesChart'
import { CandlestickChart } from 'examples/tradingview/CandlestickChart'
import { HistogramChart } from 'examples/tradingview/HistogramChart'
import { LineChart } from 'examples/tradingview/LineChart'

import { AdminPage } from 'pages/admin/AdminPage'
import { APP_ID } from 'utils/constants'
import { useEffect } from 'react'
import { ReactOauthGoogleSimple } from 'examples/auth/ReactOauthGoogleSimple'
import { OauthSimpleWithRealm } from 'examples/auth/OauthSimpleWithRealm'
import { MongoEmailAuthDemo } from 'examples/auth/MongoEmailAuthDemo'
import { MongoEmailAuthFormDemo } from 'examples/auth/MongoEmailAuthFormDemo'

const App = () => {
  useEffect(() => {
    console.log('APP_ID: ', APP_ID)
  }, [])

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/auth" element={<Auth />} />
          <Route path="/confirm-email" element={<ConfirmEmail />} />
          <Route path="/" element={<AppContainer />}>
            {/* <Route index element={<Homepage />} /> */}
            <Route element={<DashboardContainer />}>
              <Route index element={<Dashboard />} />
              <Route path="demo" element={<DashboardDemo />} />
              <Route path="tests" element={<Tests />} />
              <Route path="list" element={<StockList />} />
              <Route path="admin" element={<AdminPage />} />
            </Route>
            <Route path="crud" element={<HookCrud />} />
            <Route path="crud-simple" element={<CRUD />} />
            <Route path="grid" element={<DataGridDemo />} />
            <Route path="area" element={<AreaSeriesChart />} />
            <Route path="wrap" element={<SimpleWrapper />} />
            <Route path="bar" element={<SimpleBarSeriesChart />} />
            <Route path="base" element={<BaselineSeriesChart />} />
            <Route path="candle" element={<CandlestickChart />} />
            <Route path="hist" element={<HistogramChart />} />
            <Route path="line" element={<LineChart />} />
            <Route path="examples">
              <Route path="email" element={<MongoEmailAuthDemo />} />
              <Route path="form" element={<MongoEmailAuthFormDemo />} />
              <Route path="simple" element={<ReactOauthGoogleSimple />} />
              <Route path="realm" element={<OauthSimpleWithRealm />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  )
}

export default App
