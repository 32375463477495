import { useMemo } from 'react'
import { Chart, HistogramSeries } from 'react-lightweight-charts-simple'

const samples = [
  { value: 1, time: '2022-01-01' },
  { value: 8, time: '2022-01-02' },
  { value: 10, time: '2022-01-03' },
  { value: 20, time: '2022-01-04' },
  { value: 3, time: '2022-01-05' },
  { value: 43, time: '2022-01-06' },
  { value: 41, time: '2022-01-07' },
  { value: 43, time: '2022-01-08' },
  { value: 56, time: '2022-01-09' },
  { value: 46, time: '2022-01-10' },
]

export const HistogramChart = () => {
  // Memoize data to prevent setting data multiple times
  const data = useMemo(() => [...samples], [])

  // Histogram Chart
  // https://tradingview.github.io/lightweight-charts/docs/series-types#histogram
  return (
    <Chart height={300}>
      <HistogramSeries
        data={data}
        options={{
          color: '#26a69a',
        }}
      />
    </Chart>
  )
}
