import { useMemo } from 'react'
import { Chart, CandlestickSeries } from 'react-lightweight-charts-simple'

export const CandlestickChart = () => {
  // Memoize data to prevent setting data multiple times
  const data = useMemo(
    () => [
      { open: 10, high: 10.63, low: 9.49, close: 9.55, time: '2022-01-01' },
      { open: 9.55, high: 10.3, low: 9.42, close: 9.94, time: '2022-01-02' },
      { open: 9.94, high: 10.17, low: 9.92, close: 9.78, time: '2022-01-03' },
      { open: 9.78, high: 10.59, low: 9.18, close: 9.51, time: '2022-01-04' },
      { open: 9.51, high: 10.46, low: 9.1, close: 10.17, time: '2022-01-05' },
      { open: 10.17, high: 10.96, low: 10.16, close: 10.47, time: '2022-01-06' },
      { open: 10.47, high: 11.39, low: 10.4, close: 10.81, time: '2022-01-07' },
      { open: 10.81, high: 11.6, low: 10.3, close: 10.75, time: '2022-01-08' },
      { open: 10.75, high: 11.6, low: 10.49, close: 10.93, time: '2022-01-09' },
      { open: 10.93, high: 11.53, low: 10.76, close: 10.96, time: '2022-01-10' },
    ],
    []
  )

  // Candlestick Chart
  // https://tradingview.github.io/lightweight-charts/docs/series-types#candlestick
  return (
    <Chart height={300}>
      <CandlestickSeries
        data={data}
        options={{
          upColor: '#26a69a',
          downColor: '#ef5350',
          borderVisible: false,
          wickUpColor: '#26a69a',
          wickDownColor: '#ef5350',
        }}
      />
    </Chart>
  )
}
