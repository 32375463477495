import { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { CircularProgress } from '@material-ui/core'

import {
  countTests,
  createTest,
  deleteTest,
  fetchAllTests,
  fetchTest,
  searchTests,
} from 'services/TestService'

const useStyles = makeStyles(
  theme => ({
    table: {
      // minWidth: 650,
    },

    user: {
      marginBottom: theme.spacing(2),
    },

    loading: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'Tests' }
)

type TestResult = {
  name: string
  result: boolean
  data: any
  count?: number
}

const Tests = () => {
  const classes = useStyles()

  const [loading, setLoading] = useState<boolean>(true)

  const [results, setResults] = useState<TestResult[]>([])

  useEffect(() => {
    const runTests = async () => {
      const newResults: TestResult[] = []

      // Fetch all the tests again
      const fetchAllFirst = await fetchAllTests()
      newResults.push({
        name: 'Fetch all existing tests',
        result: Array.isArray(fetchAllFirst),
        data: fetchAllFirst,
        count: fetchAllFirst.length,
      })

      // Delete all tests
      const tests = await fetchAllTests()
      tests.forEach(doc => {
        deleteTest(doc.id)
      })

      // Check that they're all gone
      const countResults = await countTests()
      newResults.push({
        name: 'Delete all tests and check the collection is empty',
        result: countResults === 0,
        data: countResults,
        count: countResults,
      })

      // Create a test
      const result = await createTest({ name: 'test' })
      newResults.push({
        name: 'Create a test',
        result: typeof result.id === 'string',
        data: result,
      })

      // Fetch all the tests again
      const fetchAllResults = await fetchAllTests()
      newResults.push({
        name: 'Fetch all existing tests',
        result: fetchAllResults.length === 1,
        data: fetchAllResults,
        count: fetchAllResults.length,
      })

      // Check that one test exists
      const countResults1 = await countTests()
      newResults.push({
        name: 'Check the collection has one test',
        result: countResults1 === 1,
        data: countResults1,
        count: countResults1,
      })

      // Fetch all the tests again
      const fetchAllResults1 = await fetchAllTests()
      newResults.push({
        name: 'Fetch all existing tests',
        result: fetchAllResults1.length === 1,
        data: fetchAllResults1,
        count: fetchAllResults1.length,
      })

      // Check that test exists and matches the created test
      const id = result.id
      const fetchResult = await fetchTest(id)
      const fetchedId = fetchResult?.id
      newResults.push({
        name: 'Fetch the created test',
        result: id === fetchedId,
        data: fetchResult,
      })

      // Search for the test
      const searchResults = await searchTests('name', '==', 'test')
      newResults.push({
        name: 'Search for the test',
        result: searchResults.length === 1,
        data: searchResults,
        count: searchResults.length,
      })

      // Delete the test
      await deleteTest(id)

      // Check that it's gone
      const countResults2 = await countTests()
      newResults.push({
        name: 'Delete the test and check the collection is empty',
        result: countResults2 === 0,
        data: countResults2,
        count: countResults2,
      })

      setResults(newResults)
      setLoading(false)
    }
    runTests()
  }, [])

  return (
    <div>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Test Name</TableCell>
                <TableCell align="center">Pass?</TableCell>
                <TableCell align="center">Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((row, index) => (
                <TableRow
                  key={index}
                  onClick={() =>
                    console.info(JSON.stringify({ name: row.name, data: row.data }, null, 2))
                  }
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">{row.result ? 'OK' : 'FAIL'}</TableCell>
                  <TableCell align="center">{row.count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
}

export default Tests
