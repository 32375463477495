import { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { CircularProgress } from '@material-ui/core'

import {
  countTests,
  createTest,
  deleteTest,
  fetchAllTests,
  fetchTest,
  searchTests,
} from 'services/TestService'

import { useFirebaseAuth } from 'services/hooks/useFirebaseAuth'

const useStyles = makeStyles(
  theme => ({
    table: {
      // minWidth: 650,
    },

    user: {
      marginBottom: theme.spacing(2),
    },

    loading: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'Tests' }
)

type TestResult = {
  name: string
  result: boolean
  data: any
  count: number
}

const StockList = () => {
  const classes = useStyles()

  const { user, loading: userLoading } = useFirebaseAuth()

  const [loading, setLoading] = useState<boolean>(true)

  const [results, setResults] = useState<TestResult[]>([])

  useEffect(() => {
    const fetchStockList = async () => {
      const newResults: TestResult[] = []

      setResults(newResults)
      setLoading(false)
    }
    fetchStockList()
  }, [])

  return (
    <div>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Test Name</TableCell>
                <TableCell align="center">Pass?</TableCell>
                <TableCell align="center">Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((row, index) => (
                <TableRow
                  key={index}
                  onClick={() =>
                    console.info(JSON.stringify({ name: row.name, data: row.data }, null, 2))
                  }
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">{row.result ? 'OK' : 'FAIL'}</TableCell>
                  <TableCell align="center">{row.count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
}

export default StockList
