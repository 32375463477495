/**
 * Google One Tap using OpenId Connect
 * https://developers.google.com/identity/openid-connect/openid-connect#an-id-tokens-payload
 */
import { createContext, useState, useContext, Dispatch, SetStateAction, ReactNode } from 'react'
import { IGoogleEndPointResponse } from 'react-google-one-tap-login/dist/types/types'

// Response key definitions: https://stackoverflow.com/a/31070561
export interface AppUserInterface extends IGoogleEndPointResponse {}

type AppUserProvider = {
  children: ReactNode
  value?: Partial<AppUserInterface>
}

const AppUserContext = createContext({
  appUser: {} as Partial<AppUserInterface>,
  setAppUser: {} as Dispatch<SetStateAction<Partial<AppUserInterface>>>,
})

export const AppUserProvider = ({ children, value = {} as AppUserInterface }: AppUserProvider) => {
  const [appUser, setAppUser] = useState(value)
  return (
    <AppUserContext.Provider value={{ appUser, setAppUser }}>{children}</AppUserContext.Provider>
  )
}

export const useAppUser = () => {
  const context = useContext(AppUserContext)
  if (!context) {
    throw new Error('useAppUser must be used within a AppUserContext')
  }
  return context
}
